
import HeaderLogo from "../assets/logo-img.png";

export default function Header() {
  return (
    <header className="w-full center-items bg-black h-[80px] fixed top-0 left-0 right-0">
      <img src={HeaderLogo} alt="logo" className="h-full" />
    </header>
  );
}
