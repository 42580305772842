import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import React, { useEffect, useRef } from "react";
import config from "../config";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Spinner from "./Spinner";

interface Document {
  patientName: string;
  patientSurname: string;
  documentHtml: string;
  checkHash: string;
}

interface CheckValue {
  id: string;
  value: boolean;
}

interface FetchDocumentsResponse {
  data: Document[];
}

interface FetchDocumentsRequestConfig extends AxiosRequestConfig {
  headers: {
    Authorization: string;
  };
}

export default function Documents() {
  const [isFetched, setIsFetched] = React.useState(false);
  const [documents, setDocuments] = React.useState<Document[] | any>([]);
  const [isShowingDocument, setIsShowingDocument] = React.useState(false);
  const [checkValues, setCheckValues] = React.useState<CheckValue[]>([]);
  const [isAllChecked, setIsAllChecked] = React.useState(false);
  const canvasRef = useRef<any>(null);
  const [hasBeenDrawed, setHasBeenDrawed] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [consentSigns, setConsentSigns] = React.useState<any>([]);
  const navigate = useNavigate();

  async function fetchDocuments(simulate?: string): Promise<void> {
    try {
      const reqConfig: FetchDocumentsRequestConfig = {
        headers: {
          Authorization: `Bearer ${
            simulate || localStorage.getItem("accessToken")
          }`,
        },
      };

      // Perform the API request with proper typing
      setIsLoading(true);
      const response: AxiosResponse<FetchDocumentsResponse> = await axios.get(
        `${config.baseUrl}/pending-signature`,
        reqConfig
      );

      // Extract the documents array from the response
      const docs = response.data;

      // Update the state with the fetched documents
      setDocuments(docs); // Pass the array directly
      console.log(docs);
      setIsFetched(true);
      setIsLoading(false);
    } catch (error: unknown) {
      setIsLoading(false);
      // Type guard to check if error is an Axios error
      if (axios.isAxiosError(error)) {
        if (error.response?.data.error === "Broken") {
          toast.error("Document config is broken. Please contact support");
          return;
        }

        console.log(error);

        if (error.response?.status === 401) {
          toast.error("Please login again");
          navigate("/");
          return;
        }
      } else {
        // Handle unexpected errors
        console.error("An unexpected error occurred:", error);
      }
    }
  }

  function onCancel() {
    setCheckValues([]);
    setIsShowingDocument(false);
  }

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    console.log(consentSigns);
    if (
      consentSigns &&
      consentSigns.find((sign: any) => sign.isTouched === false)
    ) {
      setHasBeenDrawed(false);
    } else {
      setHasBeenDrawed(true);
    }
  }, [consentSigns]);

  useEffect(() => {
    if (checkValues.find((checkValue) => checkValue.value === false)) {
      setIsAllChecked(false);
    } else {
      setIsAllChecked(true);
    }
  }, [checkValues]);

  async function submit(simulate?: string) {
    try {
      setIsLoading(true);
      console.log("submitting");
      console.log(canvasRef.current);
      const sigValues = canvasRef.current.map((canvas: any) => {
        return {
          id: canvas.id,
          image: canvas.toDataURL("image/png"),
        };
      });
      await axios.post(
        `${config.baseUrl}/submit-signature`,
        {
          checkHash: documents[0].checkHash,
          checkValues: checkValues,
          signatureValues: sigValues,
        },
        {
          headers: {
            Authorization: `Bearer ${
              simulate || localStorage.getItem("accessToken")
            }`,
          },
        }
      );

      toast.success("Document submitted successfully");
      setIsLoading(false);
      navigate("/consent-submitted");
    } catch (error: any) {
      setIsLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        toast.error("You have logged out of the system. Please login again");
        navigate("/");
        return;
      }
    }
  }

  useEffect(() => {
    if (isShowingDocument) {
      document.querySelectorAll("consent-check").forEach((element) => {
        setCheckValues((prev) => [
          ...prev,
          {
            id: element.id,
            value: false,
          },
        ]);

        let inputElement = document.createElement("input");
        inputElement.id = element.id;
        inputElement.type = "checkbox";
        element.appendChild(inputElement);

        element.parentElement!.addEventListener("click", () => {
          inputElement.click();
        });

        element.parentElement!.style.cursor = "pointer";
        inputElement.style.pointerEvents = "none";

        inputElement.addEventListener("change", (e: any) => {
          setCheckValues((prev) => {
            if (prev.find((checkValue) => checkValue.id === element.id)) {
              return prev.map((checkValue) => {
                if (checkValue.id === element.id) {
                  return { ...checkValue, value: e.target.checked };
                }
                return checkValue;
              });
            } else {
              return [
                ...prev,
                {
                  id: element.id,
                  value: e.target.checked,
                },
              ];
            }
          });
        });
      });
    }
  }, [isShowingDocument]);

  useEffect(() => {
    if (isShowingDocument) {
      const signElements = document.querySelectorAll("consent-sign");
      const consSigns = Array.from(signElements).map(element => ({
        id: (element as HTMLElement).id,
        isTouched: false,
      }));
      setConsentSigns(consSigns);

      document.querySelectorAll("table").forEach((table, index) => {
        const tableElement = table as HTMLElement;
        if (index === 0) {
          tableElement.classList.add("first-table");
        }

        if (table.querySelector(".consent-sign")) {
          tableElement.classList.add("has-consent-sign");
        }

        if (table.querySelector(".consent-check")) {
          tableElement.classList.add("has-consent-check");
        }
      });

      console.log('signelements', signElements)

      signElements.forEach((element, index) => {
        const signElement = element as HTMLElement;
        console.log('signElement', signElement)
        signElement.setAttribute("style", "height: 180px; display: flex; width: 100%;");

        const canvasElement = document.createElement("canvas");
        canvasElement.id = signElement.id;
        canvasElement.style.border = "1px solid black";
        canvasElement.setAttribute(
          "style",
          `width: ${0.7 * element.clientWidth || 0}px;height: ${
            element.clientHeight - 34 || 0
          }px;`
        );
        canvasElement.width = signElement.clientWidth * 0.7; // Set canvas width
        canvasElement.height = signElement.clientHeight - 34; // Set canvas height
        console.log('before append child', canvasElement)
        signElement.appendChild(canvasElement);

        if (!canvasRef.current) {
          canvasRef.current = [];
        }
        canvasRef.current[index] = canvasElement;

        const context = canvasElement.getContext("2d");
        if (!context) return;

        let isDrawing = false;

        const startDrawing = (event: MouseEvent | TouchEvent) => {
          setConsentSigns((prev: any) =>
            prev.map((sign: any) =>
              sign.id === canvasElement.id
                ? { ...sign, isTouched: true }
                : sign
            )
          );
          isDrawing = true;
          draw(event);
        };

        const stopDrawing = () => {
          isDrawing = false;
          context.beginPath(); // Reset the current path
        };

        const draw = (event: MouseEvent | TouchEvent) => {
          if (!isDrawing) return;

          context.lineWidth = 5;
          context.lineCap = "round";
          context.strokeStyle = "black";

          const rect = canvasElement.getBoundingClientRect();
          const scaleX = canvasElement.width / rect.width;
          const scaleY = canvasElement.height / rect.height;

          let clientX: number, clientY: number;
          if (event instanceof MouseEvent) {
            clientX = event.clientX;
            clientY = event.clientY;
          } else {
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
          }

          const x = (clientX - rect.left) * scaleX;
          const y = (clientY - rect.top) * scaleY;

          context.lineTo(x, y);
          context.stroke();
          context.beginPath();
          context.moveTo(x, y);
        };

        const handleMouseDown = (event: MouseEvent) => startDrawing(event);
        const handleMouseUp = () => stopDrawing();
        const handleMouseMove = (event: MouseEvent) => draw(event);
        const handleTouchStart = (event: TouchEvent) => {
          event.preventDefault();
          startDrawing(event);
        };
        const handleTouchEnd = (event: TouchEvent) => {
          event.preventDefault();
          stopDrawing();
        };
        const handleTouchMove = (event: TouchEvent) => {
          event.preventDefault();
          draw(event);
        };

        canvasElement.addEventListener("mousedown", handleMouseDown);
        canvasElement.addEventListener("mouseup", handleMouseUp);
        canvasElement.addEventListener("mousemove", handleMouseMove);
        canvasElement.addEventListener("touchstart", handleTouchStart);
        canvasElement.addEventListener("touchend", handleTouchEnd);
        canvasElement.addEventListener("touchmove", handleTouchMove);

        return () => {
          canvasElement.removeEventListener("mousedown", handleMouseDown);
          canvasElement.removeEventListener("mouseup", handleMouseUp);
          canvasElement.removeEventListener("mousemove", handleMouseMove);
          canvasElement.removeEventListener("touchstart", handleTouchStart);
          canvasElement.removeEventListener("touchend", handleTouchEnd);
          canvasElement.removeEventListener("touchmove", handleTouchMove);
        };
      });
    }
  }, [isShowingDocument]);

  function handleCancel() {
    setDocuments([]);
    setIsFetched(false);
  }

  return (
    <div>
      {isShowingDocument ? (
        <div className="h-[calc(100vh-85px)] relative overflow-hidden">
          <div className="bg-[#F8F8F8] h-24 flex justify-between px-8 items-center document-header">
            <h2>
              {!isAllChecked || !hasBeenDrawed
                ? "Please sign the consent form first"
                : "You can now submit this form"}
            </h2>

            <div className="flex gap-x-3">
              <button
                className="green-rounded-button !bg-white hover:underline !text-black !font-medium"
                onClick={() => onCancel()}
              >
                Cancel
              </button>

              <button
                className="green-rounded-button"
                disabled={!isAllChecked || !hasBeenDrawed || isLoading}
                onClick={() => submit()}
              >
                {isLoading ? <Spinner /> : "Submit"}
              </button>
            </div>

            {config.testMode && (
              <button
                className="green-rounded-button"
                disabled={!isAllChecked || !hasBeenDrawed}
                onClick={() => submit("access2")}
              >
                Simulate access2
              </button>
            )}
          </div>

          <div className="w-full h-full bg-white overflow-auto">
            <div
              className="imported-form max-h-[calc(100vh-85px-96px)] pb-6 container px-4 mx-auto"
              dangerouslySetInnerHTML={{ __html: documents[0].documentHtml }}
            ></div>
          </div>
        </div>
      ) : (
        <>
          <section className="w-full h-[40vh] center-items flex-col">
            {documents.length > 0 ? (
              documents.map((patientDocument: any, index: number) => (
                <div className="flex flex-col items-center " key={index}>
                  <h2 className="text-green mb-4">Document ready</h2>

                  <h3 className="mb-7">
                    Patient name: {patientDocument.patientName}{" "}
                    {patientDocument.patientSurname}
                  </h3>

                  <button
                    className="green-rounded-button mb-4"
                    onClick={() => setIsShowingDocument(true)}
                  >
                    Show document
                  </button>

                  <button
                    className="hover:underline"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </button>
                </div>
              ))
            ) : (
              <div className="center-items flex-col pt-4 pb-6">
                <h2> Ready to receive documents</h2>

                <button
                  className="green-rounded-button mt-[18px] disabled:opacity-50"
                  onClick={() => fetchDocuments()}
                  disabled={isLoading}
                >
                  {isLoading ? <Spinner /> : "Retrieve Documents"}
                </button>

                {config.testMode && (
                  <button
                    className="green-rounded-button mt-[18px]"
                    onClick={() => fetchDocuments("broken")}
                  >
                    Simulate broken
                  </button>
                )}

                {config.testMode && (
                  <button
                    className="green-rounded-button mt-[18px]"
                    onClick={() => fetchDocuments("expired")}
                  >
                    Simulate expired
                  </button>
                )}

                {isFetched ? (
                  <p className="text-orange text-center mt-5">
                    There are no documents waiting to be signed.
                    <br /> Please check Meddbase and try again.
                  </p>
                ) : null}
              </div>
            )}
          </section>
          <Footer />
        </>
      )}
    </div>
  );
}
