import "./App.css";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login";
import Documents from "./components/Documents";
import ConsentSubmitted from "./components/ConsentSubmitted";
import AuthenticateToken from "./components/AuthenticateToken";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Header />
      <div className="flex flex-col h-screen pt-[80px] overflow-hidden">
        <ToastContainer />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/DirectLogin.aspx" element={<AuthenticateToken />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/consent-submitted" element={<ConsentSubmitted />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
