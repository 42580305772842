import { useNavigate } from "react-router-dom";

export default function ConsentSubmitted() {
  const navigate = useNavigate();

  function handleClose() {
    navigate("/documents");
  }
  return (
    <>
      <div className="h-[50vh] flex flex-col justify-center center-items">
        <h2 className="text-center mb-[100px]">
          Form submission successful. <br />
          You can hand the tablet back to the clinician.
        </h2>

        <button
          className="green-rounded-button w-max"
          onClick={() => handleClose()}
        >
          Close
        </button>
      </div>
    </>
  );
}
