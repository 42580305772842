import { Link } from "react-router-dom";
import Footer from "./Footer";

export enum LoginResponseKind {
  Success,
  Wait,
  NoSession,
  UnknownError,
}

export interface LoginResponseSuccess {
  _kind: LoginResponseKind.Success;
}

export interface LoginResponseNoSession {
  _kind: LoginResponseKind.NoSession;
}

export interface LoginResponseUnknown {
  _kind: LoginResponseKind.UnknownError;
  error: string;
}

export interface LoginFailedWait {
  _kind: LoginResponseKind.Wait;
  state: string | undefined;
}

export type LoginResponse =
  | LoginResponseSuccess
  | LoginResponseNoSession
  | LoginResponseUnknown
  | LoginFailedWait;

export default function Login() {
  return (
    <>
      <div className="h-[40vh] center-items flex-col">
        <h2>Please login to begin</h2>

        <Link
          to="https://steve-login.olduwan.com/Default.aspx?client=sddwinc"
          className="green-rounded-button mt-[18px]"
        >
          Login
        </Link>
      </div>
      <Footer />
    </>
  );
}
