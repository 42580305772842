import axios from "axios";
import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import config from "../config";
import { toast } from "react-toastify";
import Spinner from "./Spinner";

export default function AuthenticateToken() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [responseState, setResponseState] = React.useState<{
    state: string;
  } | null>(null);
  const [loginFailed, setLoginFailed] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  async function login(token: string) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    try {
      setIsLoading(true);
      const req = await axios.post(`${config.baseUrl}/login`, {
        token: token,
        ...(responseState && { state: responseState?.state }),
      });

      setResponseState({
        state: req.data?.state || null,
      });

      localStorage.setItem("accessToken", req.data.accessToken);
      setIsLoading(false);
      navigate("/documents");
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
      setLoginFailed(true);

      if (err.response.data.error === "NoSession") {
        toast.error("The session has expired, please try again");
        return;
      }

      if (err.response.data.error === "Invalid") {
        toast.error("Something went wrong, please try again");
        return;
      }

      if (err.response.status === 429) {
        toast.error("Too many requests, please try again later");
        return;
      }
    }
  }

  useEffect(() => {
    const token = searchParams.get("id");

    if (token) {
      localStorage.setItem("token", token);
      login(token);
    }
  }, []);

  return (
    <div className="flex justify-center w-full py-4">
      {isLoading && <Spinner />}
      {loginFailed && (
        <Link
          to="https://steve-meddbase.olduwan.com/"
          className="green-rounded-button mt-[18px] w-max"
          target="_blank"
          onClick={() => navigate("/")}
        >
          Login
        </Link>
      )}
    </div>
  );
}
